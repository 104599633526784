var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "button",
    {
      staticClass: "button df ai-c jc-c",
      class: { "button-disabled": _vm.isLoading || _vm.disabled },
      style: _vm.buttonStyle,
      attrs: { disabled: _vm.isLoading || _vm.disabled },
      on: {
        click: function ($event) {
          return _vm.$emit("onClick")
        },
      },
    },
    [
      _c("div", { staticClass: "button-text", style: _vm.textStyle }, [
        _vm._v(" " + _vm._s(_vm.titleButton) + " "),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }